import React, { useEffect, useState } from "react";
import { Menu, Spin } from "antd";
import Axios from "../../AxiosWrapper";

import { Link, Route, useNavigate, useParams, Routes } from "react-router-dom";
import { User } from "../../types";
import { showError } from "../../utils";
import GameDataView from "../../Components/GameDataView";
import { UserSettingsList } from "../../Components/UserSettingsList";
import { Affiliate } from "./Affiliate";
import { UserLicenseInfo } from "./UserLicenseInfo";
import { UserOrders } from "./UserOrders";
import { UserDetails } from "./UserDetails";
import { Turnkey } from "./Turnkey";
import { useAuth } from "../../contexts/AuthContext";
import { SalesOverview } from "./SalesOverview";

export const UserPage = () => {
  const [user, setUser] = useState<User>();
  const { auth } = useAuth();
  const { userId } = useParams<{ userId: string }>();
  useEffect(() => {
    fetchUser();
  }, []);
  const fetchUser = async () => {
    try {
      const userInfo = await Axios.get(`/api/admin/users/${userId}`);
      setUser(userInfo.data);
    } catch (error) {
      showError("failed to get user info", error);
    }
  };

  const renderSubPage = () => {
    if (!user) return <Spin />;
    return (
      <Routes>
        <Route path="affiliate" element={<Affiliate userId={user.id} />} />
        <Route
          path="license"
          element={<UserLicenseInfo user={user} onSuccess={fetchUser} />}
        />
        <Route
          path="settings"
          element={<UserSettingsList userId={user.id} />}
        />
        <Route path="game-stats" element={<GameDataView user={user} />} />
        <Route path="orders" element={<UserOrders userId={user.id} />} />

        <Route path="turnkey" element={<Turnkey userId={user.id} />} />
        <Route path="sales-overview" element={<SalesOverview user={user} />} />
        <Route
          index
          element={<UserDetails user={user} onSuccess={fetchUser} />}
        />
      </Routes>
    );
  };
  return (
    <div
      style={{
        flex: 1,
        display: "grid",
        gridTemplateColumns: "250px auto",
      }}
    >
      <Menu style={{ width: 200, height: "500px" }} mode="inline">
        <Menu.Item key="Main">
          <Link to="">User Details</Link>
        </Menu.Item>

        <Menu.Item key="license">
          <Link to={`license`}>License</Link>
        </Menu.Item>

        <Menu.Item key="game-stats">
          <Link to={`game-stats`}>Game Stats</Link>
        </Menu.Item>
        <Menu.Item key="orders">
          <Link to={`orders`}>Orders</Link>
        </Menu.Item>
        {auth?.role === "admin" && (
          <>
            <Menu.Item key="turnkey">
              <Link to={`turnkey`}>Turnkey</Link>
            </Menu.Item>
            <Menu.Item key="Settings">
              <Link to={`settings`}>Settings</Link>
            </Menu.Item>
            <Menu.Item key="affiliate">
              <Link to={`affiliate`}>Affiliate</Link>
            </Menu.Item>
            {user?.role === "sales" && (
              <Menu.Item key="sales-overview">
                <Link to={`sales-overview`}>Sales Overview</Link>
              </Menu.Item>
            )}
          </>
        )}
      </Menu>
      {renderSubPage()}
    </div>
  );
};
