import { Button, Select, Table, message } from "antd";
import React, { useEffect, useState } from "react";
import { showError } from "../../utils";
import { User } from "../../types";
import Axios from "../../AxiosWrapper";

export const SalesOverview = (props: { user: User }) => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await Axios.get(
        `/api/admin/users/${props.user.id}/assigned_users`
      );
      setUsers(response.data);
    } catch (error) {
      showError("Failed to fetch assigned users", error);
    } finally {
      setLoading(false);
    }
  };

  const unassignUser = async (user: User) => {
    try {
      await Axios.post(`/api/admin/unassign_user`, {
        userId: user.id,
      });
      message.success("Successfully removed user");
      fetchUsers();
    } catch (error) {
      showError("Failed to remove user", error);
    }
  };
  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "",
      key: "actions",
      render: (user: User) => (
        <Button onClick={() => unassignUser(user)}>Remove</Button>
      ),
    },
  ];

  return (
    <div style={{ padding: 24 }}>
      <h2>Assigned Users</h2>
      <Table
        loading={loading}
        dataSource={users}
        columns={columns}
        rowKey="id"
        pagination={{ pageSize: 50 }}
      />

      <div style={{ marginTop: 24 }}>
        <h3>Assign User</h3>
        <AssignUsers user={props.user} onSuccess={fetchUsers} />
      </div>
    </div>
  );
};

const AssignUsers = (props: { user: User; onSuccess: () => void }) => {
  const [users, setUsers] = useState<{ id: number; email: string }[]>();
  const [userIdToAdd, setUserIdToAdd] = useState<number>();
  useEffect(() => {
    const fetchAvailableUsers = async () => {
      try {
        const res = await Axios.get(`/api/admin/list_users`);
        setUsers(res.data);
      } catch (error) {
        showError("failed to get list of users", error);
      }
    };
    fetchAvailableUsers();
  }, []);

  const addUser = async () => {
    try {
      await Axios.post(`/api/admin/assign_user`, {
        userId: userIdToAdd,
        salesUserId: props.user.id,
      });
      message.success("Successfully added user");
      props.onSuccess();
    } catch (error) {
      showError("failed to add user", error);
    }
  };
  return (
    <div>
      <Select
        onSelect={(value: any) => setUserIdToAdd(+value)}
        showSearch
        style={{ width: 500 }}
        filterOption={(input: any, option: any) =>
          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {users?.map((user) => (
          <Select.Option key={user.id} value={user.id}>
            {user.email}
          </Select.Option>
        ))}
      </Select>
      <Button disabled={!userIdToAdd} onClick={() => addUser()}>
        Add
      </Button>
    </div>
  );
};
