import {
  Button,
  Card,
  DatePicker,
  InputNumber,
  message,
  Modal,
  Select,
  Typography,
} from "antd";
import moment from "moment";
import React, { useState } from "react";
import Axios from "../../AxiosWrapper";
import { User } from "../../types";
import { showError } from "../../utils";
import { useAuth } from "../../contexts/AuthContext";
const { Title } = Typography;
export const UserLicenseInfo = (props: {
  user: User;
  onSuccess: () => void;
}) => {
  const { user } = props;
  if (!user) return null;
  const renderLicenseInfo = () => {
    if (user.license_type === "credits")
      return (
        <>
          {" "}
          <b>Credits Available: </b> {user.credits} <br /> <br />
          <GiveCredits userId={user.id} onSuccess={props.onSuccess} />
        </>
      );

    return (
      <>
        <b> Current expiry date: </b>
        {user.license_expiry_date
          ? user.license_expiry_date.split("T")[0]
          : null}
        <br />
        <ChangeLicenseDateModal user={user} onSuccess={props.onSuccess} />
      </>
    );
  };

  return (
    <Card>
      <Title level={3}>License Info</Title>
      <br />
      <br />
      <b>License Type:</b> {user.license_type}
      <br />
      {renderLicenseInfo()}
      <br />
      <br />
      <ChangeLicenseTypeButton
        onChangeLicense={props.onSuccess}
        currentUser={user}
      />
    </Card>
  );
};

const ChangeLicenseDateModal = (props: {
  user: User;
  onSuccess: () => void;
}) => {
  const [showModal, setShowModal] = useState(false);
  const [newDate, setNewDate] = useState<string>();
  const { auth } = useAuth();
  if (!auth || auth.role !== "admin") return null;
  const close = () => {
    setShowModal(false);
  };
  const updateUserLicenseDate = async () => {
    try {
      if (!newDate) throw Error("Please choose a date");
      await Axios.post("/api/admin/update_user", {
        license_expiry_date: newDate,
        user_id: props.user.id,
      });
      message.success("Updated license date");
      props.onSuccess();
      close();
    } catch (error) {
      showError("Failed to update license date", error);
    }
  };
  return (
    <>
      <Button onClick={() => setShowModal(true)}>
        Change License Expiry Date
      </Button>
      <Modal onOk={updateUserLicenseDate} visible={showModal} onCancel={close}>
        <b>New expiry date</b>
        <DatePicker
          allowClear={false}
          onChange={(date: any) => setNewDate(date.format())}
          defaultValue={getStartingDate(props.user.license_expiry_date || "")}
        />
        {newDate && (
          <>
            <br />
            {getDayDiff(
              getStartingDate(props.user.license_expiry_date || "").format(),
              newDate
            ) + " extra days"}
          </>
        )}
      </Modal>
    </>
  );
};
function getDayDiff(originalDate: string, newDate: string | null) {
  if (!originalDate) originalDate = moment().format();
  if (!newDate) newDate = moment().format();
  return Math.round(
    (new Date(newDate).getTime() - new Date(originalDate).getTime()) / 8.64e7
  );
}
function getStartingDate(date: string) {
  if (!date) return moment();
  const startingDate = moment(date);
  if (startingDate < moment()) return moment();
  return startingDate;
}
const GiveCredits = (props: { userId: number; onSuccess: () => void }) => {
  const [showModal, setShowModal] = useState(false);
  const [creditDelta, setCreditDelta] = useState(100);
  const onClose = () => {
    setShowModal(false);
  };
  const onChangeNumber = (value: any) => {
    setCreditDelta(+value);
  };
  const giveCredits = async () => {
    try {
      await Axios.post("/api/admin/give_credits", {
        amount: creditDelta,
        user_id: props.userId,
      });
      message.success("Successfully added credits");
      props.onSuccess();
    } catch (error) {
      showError("Failed to add credits", error);
    } finally {
      onClose();
    }
  };

  return (
    <>
      <Button type="primary" onClick={() => setShowModal(true)}>
        Give Credits
      </Button>
      <Modal onOk={giveCredits} visible={showModal} onCancel={onClose}>
        <InputNumber
          min={-10000}
          max={10000}
          defaultValue={100}
          onChange={onChangeNumber}
        />
      </Modal>
    </>
  );
};

type ChangeLicenseProps = {
  currentUser: User;
  onChangeLicense: () => void;
};
const ChangeLicenseTypeButton = (props: ChangeLicenseProps) => {
  const [showModal, setShowModal] = useState(false);
  const [licenseType, setLicenseType] = useState("credits");

  const close = () => {
    setShowModal(false);
  };

  const onChangeValue = (value: "credits" | "license") => {
    setLicenseType(value);
  };

  const changeLicenseType = async () => {
    try {
      await Axios.post("/api/admin/change_license_type", {
        license_type: licenseType,
        user_id: props.currentUser.id,
      });
      message.success("Successfully changed license type");
    } catch (error) {
      showError("failed to change license type", error);
    } finally {
      props.onChangeLicense();
      close();
    }
  };

  return (
    <>
      <Button type="primary" danger onClick={() => setShowModal(true)}>
        Change License Type
      </Button>
      <Modal onOk={changeLicenseType} visible={showModal} onCancel={close}>
        License type:
        <Select
          onChange={onChangeValue}
          defaultValue={props.currentUser.license_type}
        >
          <Select.Option value="credits">Credits</Select.Option>
          <Select.Option value="license">License</Select.Option>
        </Select>
      </Modal>
    </>
  );
};
