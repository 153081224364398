import { Button, Card, message, Modal, Popconfirm } from "antd";
import React, { useState } from "react";
import ChangePasswordDialog from "../../Components/ChangePasswordDialog";
import { UserDeleteOutlined } from "@ant-design/icons";
import { User } from "../../types";
import Axios from "../../AxiosWrapper";
import { showError } from "../../utils";
import { useAuth } from "../../contexts/AuthContext";

type Props = {
  user: User;
};
export const UserActions = (props: Props) => {
  const { auth } = useAuth();
  const { user } = props;
  if (!auth || auth.role !== "admin") return null;
  return (
    <Card>
      <DeleteUserButton user={user} /> <ChangePasswordModal user={user} />
    </Card>
  );
};
const ChangePasswordModal = (props: { user: User }) => {
  const [showModal, setShowModal] = useState(false);
  const close = () => {
    setShowModal(false);
  };
  return (
    <>
      <Button onClick={() => setShowModal(true)}>Change Password</Button>
      <Modal footer={null} visible={showModal} onCancel={close}>
        <ChangePasswordDialog onClose={close} currentUser={props.user} />
      </Modal>
    </>
  );
};

const DeleteUserButton = (props: { user: User }) => {
  const { user } = props;
  const deleteUser = async () => {
    if (!user) return;
    try {
      await Axios.post("/api/admin/delete_user", {
        user_id: user.id,
      });
      message.success("User deleted.");
    } catch (error) {
      showError("Failed to delete user", error);
    }
  };

  return (
    <Popconfirm
      title="Are you sure you want to delete this user?"
      onConfirm={deleteUser}
      okText="Yes"
      cancelText="No"
    >
      <Button type="primary" danger icon={<UserDeleteOutlined />}>
        Delete User
      </Button>
    </Popconfirm>
  );
};
