import React, { useEffect, useState } from "react";
import { Layout, Menu, Button, Divider, message } from "antd";
import { Routes, Route } from "react-router-dom";
import { Login } from "./pages/Login";
import { getToken, setToken, getTokenData } from "./utils";
import PrivateRoute from "./PrivateRoute";
import { Admin } from "./pages/Admin";
import { UserPage } from "./pages/user/User";
import { CustomerMap } from "./pages/CustomerMap";
import { AffiliateOverview } from "./pages/AffiliateOverview";
import { Stats } from "./pages/Stats";
import { GameStatsGraph } from "./Components/GameStatsGraph";
import { GameStatsGraph_AllGames } from "./Components/stats/GameStatsGraph_AllGames";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
const { Header, Content } = Layout;

const AppContent = () => {
  const { auth, handleLogout } = useAuth();
  const [currentMenuKey, setCurrentMenuKey] = useState(null);

  const handleMenuClick = (e: any) => {
    setCurrentMenuKey(e.key);
  };

  return (
    <Layout className="layout">
      <Header
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          color: "#fff",
          marginBottom: "2em",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <a href="/">
            <img
              src="/images/logo.png"
              alt="logo"
              style={{ height: 32, margin: 10 }}
            />
          </a>
          <Menu
            onClick={handleMenuClick}
            mode="horizontal"
            selectedKeys={[currentMenuKey || "0"]}
            selectable
            // defaultSelectedKeys={['1']}
            style={{
              width: "100%",
              lineHeight: "64px",
              background: "transparent",
            }}
          >
            <Menu.Item key="1">
              <a href="/customer-map"> Map</a>
            </Menu.Item>
            {auth?.role === "admin" && (
              <Menu.Item key="2">
                <a href="/affiliate-overview"> Affiliate</a>
              </Menu.Item>
            )}
            {auth?.role === "admin" && (
              <Menu.Item key="3">
                <a href="/stats"> Stats</a>
              </Menu.Item>
            )}
          </Menu>
        </div>
        <div>
          {auth ? (
            <div style={{ marginLeft: "auto" }}>
              <span>{auth.email}</span>

              <Divider type="vertical" />

              <Button onClick={handleLogout}>LOGOUT</Button>
            </div>
          ) : (
            <div style={{ marginLeft: "auto" }}>
              <a href="/login">
                <Button>LOGIN</Button>
              </a>
            </div>
          )}
        </div>
      </Header>
      <Content
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          paddingTop: "3em",
        }}
      >
        <div
          style={{
            maxWidth: "1600px",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "start",
            justifyContent: "center",
          }}
        >
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="/users/:userId/*"
              element={
                <PrivateRoute>
                  <UserPage />
                </PrivateRoute>
              }
            />

            <Route
              path="/"
              element={
                <PrivateRoute>
                  <Admin />
                </PrivateRoute>
              }
            />

            <Route
              path="/customer-map"
              element={
                <PrivateRoute>
                  <CustomerMap />
                </PrivateRoute>
              }
            />
            <Route
              path="/affiliate-overview"
              element={
                <PrivateRoute>
                  <AffiliateOverview />
                </PrivateRoute>
              }
            />
            <Route
              path="/stats"
              element={
                <PrivateRoute>
                  <Stats />
                </PrivateRoute>
              }
            />
            <Route
              path="/stats/game/:gameId"
              element={
                <PrivateRoute>
                  <GameStatsGraph />
                </PrivateRoute>
              }
            />

            <Route
              path="/stats/all-games"
              element={
                <PrivateRoute>
                  <GameStatsGraph_AllGames />
                </PrivateRoute>
              }
            />
          </Routes>
        </div>
      </Content>
      <br />
      <br />
    </Layout>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
};

export default App;
